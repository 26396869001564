import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import {
  ImproverGoalMember,
  LearnerMilestonesPerClient,
  LearnerCourse,
} from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'

interface LearnerAssociatedMilestonesListProps {
  type?: string
  milestones: ImproverGoalMember[]
  perClientMilestones: LearnerMilestonesPerClient[]
  learnerCourses: LearnerCourse[]
}

const LearnerAssociatedMilestonesList: FC<
  LearnerAssociatedMilestonesListProps
> = ({
  type = 'learning item',
  milestones,
  perClientMilestones,
  learnerCourses,
}) => {
  const learnerCoursesToMilestones = learnerCourses.map((learnerCourse) => {
    return {
      courseName: learnerCourse.name,
      courseMilestones: milestones.filter(
        (milestone) => milestone.attachedLearnerItemId === learnerCourse.id,
      ),
    }
  })

  return (
    <div className="flex h-full w-full flex-col pb-4">
      <Stack spacing={2}>
        {perClientMilestones?.length > 0 && (
          <>
            <div className="font-underline text-xl font-bold">Clients</div>
            <div className="mb-2">
              {`Clients that have milestone(s) linked to this ${type}:`}
            </div>
            <ul className="list-disc pl-4">
              {perClientMilestones.map((clientMilestones, index) => (
                <li key={index}>
                  <div className="mb-2 flex h-6">
                    <span className="h-6">
                      <span className="font-bold">
                        {clientMilestones.clientName}
                      </span>
                      : {clientMilestones.totalMilestones} milestone(s)
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        {perClientMilestones.length > 0 && milestones.length > 0 && (
          <div className="font-underline border-t border-gray-300 pt-4 text-xl font-bold">
            Internal
          </div>
        )}
        {milestones.length > 0 && (
          <div className="mb-2">
            {`The following milestones are tracking progression for ${
              type === 'course' ? 'this course:' : 'these courses:'
            }`}
          </div>
        )}
        {learnerCoursesToMilestones.map(
          ({ courseName, courseMilestones }, index) => {
            return (
              courseMilestones.length > 0 && (
                <div key={index}>
                  {type !== 'course' && (
                    <div className="font-bold">{courseName}:</div>
                  )}
                  {courseMilestones.map((milestone) => (
                    <Link
                      to={
                        milestone.isTemplate
                          ? routes.improverGoalsTemplate({
                              templateId: milestone.parentGoalId,
                            })
                          : routes.improverGoalsEdit({
                              goalId: milestone.id,
                            })
                      }
                      key={milestone.id}
                      className="flex items-center gap-2 text-sm text-indigo-600 hover:text-indigo-500 hover:underline"
                    >
                      <span>
                        {milestone.goalTitle}
                        {milestone.isTemplate && ' (Template)'}
                      </span>
                      <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                    </Link>
                  ))}
                </div>
              )
            )
          },
        )}
      </Stack>
    </div>
  )
}

export default LearnerAssociatedMilestonesList
