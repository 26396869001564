import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import {
  ImproverGoalMember,
  LearnerMilestonesPerClient,
  LearnerCourse,
} from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'

import Button from 'src/components/Library/Button/Button'

interface LearnerExistingMilestonesFormProps {
  onDelete: () => void
  onClose: () => void
  type?: string
  milestones: ImproverGoalMember[]
  perClientMilestones: LearnerMilestonesPerClient[]
  learnerCourses: LearnerCourse[]
}

const LearnerExistingMilestonesForm: FC<LearnerExistingMilestonesFormProps> = ({
  onDelete,
  onClose,
  milestones,
  perClientMilestones,
  learnerCourses,
  type = 'learning item',
}) => {
  const learnerCoursesToMilestones = learnerCourses.map((learnerCourse) => {
    return {
      courseName: learnerCourse.name,
      courseMilestones: milestones.filter(
        (milestone) => milestone.attachedLearnerItemId === learnerCourse.id,
      ),
    }
  })

  return (
    <div className="flex flex-col w-full h-full px-6 pb-6">
      <Stack spacing={2}>
        {perClientMilestones?.length > 0 && (
          <>
            <div className="font-bold font-underline text-xl">Clients</div>
            <div className="mb-2">
              {`Clients, whose milestone(s) will be unlinked to this ${type}:`}
            </div>
            <ul className="pl-4 list-disc">
              {perClientMilestones.map((clientMilestones, index) => (
                <li key={index}>
                  <div className="flex h-6 mb-2">
                    <span className="h-6">
                      <span className="font-bold">
                        {clientMilestones.clientName}
                      </span>
                      : {clientMilestones.totalMilestones} milestone(s)
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        {perClientMilestones.length > 0 && milestones.length > 0 && (
          <div className="pt-4 border-t border-gray-300 font-bold font-underline text-xl">
            Internal
          </div>
        )}
        {milestones.length > 0 && (
          <div className="mb-2">
            The following milestones will be unlinked and will lose progression:
          </div>
        )}
        {learnerCoursesToMilestones.map(({ courseName, courseMilestones }) => {
          return (
            courseMilestones.length > 0 && (
              <>
                {type !== 'course' && (
                  <div className="font-bold">{courseName}:</div>
                )}
                {courseMilestones.map((milestone) => (
                  <Link
                    to={
                      milestone.isTemplate
                        ? routes.improverGoalsTemplate({
                            templateId: milestone.parentGoalId,
                          })
                        : routes.improverGoalsEdit({
                            goalId: milestone.id,
                          })
                    }
                    key={milestone.id}
                    className="flex h-6 mb-2 text-indigo-600 hover:text-indigo-400 underline"
                  >
                    <ArrowTopRightOnSquareIcon className="w-6 h-6 mr-2" />
                    <span className="h-6">
                      {milestone.goalTitle}
                      {milestone.isTemplate && ' (Template)'}
                    </span>
                  </Link>
                ))}
              </>
            )
          )
        })}
        <div className="flex">
          <Button
            variant="outlined"
            onClick={onClose}
            className="w-[45%] mr-auto"
          >
            Cancel
          </Button>
          <Button
            color="error"
            onClick={() => {
              onClose()
              onDelete()
            }}
            className="w-[45%]"
          >
            Delete
          </Button>
        </div>
      </Stack>
    </div>
  )
}

export default LearnerExistingMilestonesForm
