export const DELETE_COURSE_MUTATION = gql`
  mutation DeleteLearnerCourseMutation($id: Int!) {
    deleteLearnerCourse(id: $id) {
      id
      learnerCategoryId
    }
  }
`

export const CREATE_COURSE_MUTATION = gql`
  mutation CreateLearnerCourseMutation($input: CreateLearnerCourseInput!) {
    createLearnerCourse(input: $input) {
      id
    }
  }
`

export const UPDATE_COURSE_MUTATION = gql`
  mutation UpdateLearnerCourseMutation(
    $id: Int!
    $input: UpdateLearnerCourseInput!
  ) {
    updateLearnerCourse(id: $id, input: $input) {
      id
      name
      updatedBy
      rank
      status
      learnerCategoryId
      heroStorageObjectId
    }
  }
`
export default {
  DELETE_COURSE_MUTATION,
  CREATE_COURSE_MUTATION,
  UPDATE_COURSE_MUTATION,
}
